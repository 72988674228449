export const useUIStore = defineStore('ui', {
  state: () => {
    return {
      burgerMenu: false,

      accessibility: {
        fullscreen: false,
        biggerText: 0,
        highContrast: false,
      },

      codePush: {
        currentDeployment: '',
        deploymentKeys: {
          production: 'KiC9QBXQsA2Y_zzqpFCFLWJnUuEXr56QEDsS5',
          testing: '3mElIPtYMxg-dPD65crHmjlMxR4yB2_YDS4i_',
        },
        release: '',
      },
    };
  },

  actions: {
    accessibilityUpdate(payload) {
      this.accessibility = {
        ...this.accessibility,
        ...payload,
      };

      window.localStorage.setItem('ui-accessibility', JSON.stringify(this.accessibility));
    },

    chromeExtensionSendMessage(detail) {
      console.log('chromeExtensionSendMessage', detail);
      if (document.body.getAttribute('data-chrome-extension')) {
        window.dispatchEvent(new CustomEvent('chromeExtensionSendMessage', {
          detail,
        }));

        return true;
      }

      return false;
    },
  },

  getters: {
  },
});
