export const state = () => ({
  requests: [],
  request: {},
  requestsInQueue: 0,
  perPage: 20,
  page: {
    total: 0,
    current: 1,
    hasPrevious: false,
    hasNext: false,
  },
});

export const mutations = {
  REQUESTS_SET(state, payload) {
    state.requests = payload;
  },
  REQUESTS_ADD(state, payload) {
    state.requests.push(payload);
  },
  REQUEST_SET(state, payload) {
    state.request = payload;
  },
  REQUEST_UPDATE(state, { id, data }) {
    state.requests = state.requests.map((request) => {
      if (request.id == id) {
        if (data.new_attachment) { // for new attachments from websocket
          if (!data.attachments) { data.attachments = [] }

          data.new_attachments_count = (data.new_attachments_count || 0) + 1;

          data.attachments = [...new Map([...request.attachments, data.new_attachment].map((item) => [(item.id || item.uid), item])).values()]; // filter out duplicities
          delete data.new_attachment;
        }

        request = { ...request, ...data };
      }

      return request;
    });

    if (state.request && state.request.id == id) {
      state.request = { ...state.request, ...data };

      if (data.new_attachment) { // for new attachments from websocket
        if (!state.request.attachments) { state.request.attachments = [] }

        state.request.new_attachments_count = (state.request.new_attachments_count || 0) + 1;

        state.request.attachments = [...new Map([...state.request.attachments, data.new_attachment].map((item) => [(item.id || item.uid), item])).values()]; // filter out duplicities
        delete state.request.new_attachment;
      }
    }
  },
  REQUEST_REMOVE(state, requestId) {
    state.requests = state.requests.filter((item) => item.id !== requestId);
  },
  PAGE_UPDATE(state, payload) {
    state.page = { ...state.page, ...payload };
  },
  REQUESTS_IN_QUEUE(state, payload) {
    state.requestsInQueue = payload;
  },
};

export const actions = {
  async getRequests({ state, commit }) {
    try {
      const requests = await useApi.get('client/requests', {
        params: {
          limit: state.perPage * state.page.current,
        },
      });

      commit('REQUESTS_SET', requests.results);
      commit('PAGE_UPDATE', { hasPrevious: !!requests.previous, hasNext: !!requests.next, total: requests.count });
    } catch (error) {
      ElMessage.error(useNuxtApp().$i18n.t('requests.messages.loadFailed'));
      console.error(error);
    }
  },

  async loadMoreRequests({ commit, dispatch, state }) {
    commit('PAGE_UPDATE', { current: state.page.current + 1 });

    await dispatch('getRequests');
  },

  async getRequest({ commit }, requestId) {
    const request = await useApi.get(`/client/requests/${requestId}`);

    commit('REQUEST_SET', request);
  },

  async cancelRequest({ commit }, requestId) {
    try {
      await useApi.delete(`/client/requests/${requestId}`);
      commit('REQUEST_REMOVE', requestId);
      ElMessage.success(useI18n().t('requests.messages.deleted'));
    } catch (error) {
      ElMessage.error(useI18n().t('requests.messages.deleteFailed'));
      console.error(error);
    }
  },

  async addRequest({ commit }, data) {
    const response = await useApi.post('/client/requests', {
      body: data,
    });

    commit('REQUESTS_ADD', response);

    return response;
  },

  async updateRequest({ commit }, { id, data }) {
    const response = await useApi.patch(`/client/requests/${id}`, {
      body: data,
    });

    commit('REQUEST_UPDATE', { id, data });

    return response;
  },
};

export const getters = {
  getOpenRequests: (state) => {
    return state.requests.filter((request) => (request.state_id !== 4 && request.state_id !== 5));
  },

  getDoneRequests: (state) => {
    return state.requests.filter((request) => (request.state_id === 4 || request.state_id === 5));
  },

  request: (state) => (requestId) => {
    return state.requests.find((r) => r.id == requestId);
  },
};
