const FILTER_BASE = {
  search: '',
  states: [],
  roles: [],
  createdAt: [],
  lang: null,
};

export const state = () => ({
  total: 0,
  perPage: 25,
  users: [],
  user: {},
  states: [
    {
      id: 2,
      title: 'Aktivní',
    },
    {
      id: 3,
      title: 'Blokovaný',
    },
    {
      id: 1,
      title: 'Ke schválení',
    },
  ],
  roles: [
    {
      id: 5,
      title: 'Klient',
    },
    {
      id: 4,
      title: 'Organizace',
    },
    {
      id: 2,
      title: 'Tlumočník',
    },
    {
      id: 3,
      title: 'Přepisovatel',
    },
    {
      id: 1,
      title: 'Admin',
    },
  ],
  page: {
    current: 1,
  },
  filter: FILTER_BASE,
});

export const mutations = {
  USERS_SET(state, payload) {
    state.users = payload;
  },
  USER_SET(state, payload) {
    state.user = payload;
  },
  USERS_TOTAL_SET(state, payload) {
    state.total = payload;
  },
  USER_ADD(state, payload) {
    state.users.push(payload);
  },
  USER_UPDATE(state, { id, data }) {
    state.users = state.users.map((user) => {
      if (user.id === id) {
        user = { ...user, ...data };
      }

      return user;
    });

    if (state.user.id == id) {
      state.user = { ...state.user, ...data };
    }
  },
  USER_EMPLOYEES_SET(state, payload) {
    state.user.employees = payload;
  },
  USER_EMPLOYEE_UPDATE(state, { id, data }) {
    state.user.employees = state.user.employees.map((employee) => {
      if (employee.id === id) {
        employee = { ...employee, ...data };
      }

      return employee;
    });
  },
  USER_REMOVE(state, id) {
    state.users = state.users.filter((user) => user.id !== id);
  },
  PAGE_UPDATE(state, payload) {
    state.page = { ...state.page, ...payload };
  },
  FILTER_UPDATE(state, payload) {
    state.filter = { ...state.filter, ...payload };
  },
  FILTER_RESET(state) {
    state.filter = FILTER_BASE;
  },
};

export const getters = {
};

export const actions = {
  async getUsers({ commit, state }) {
    const response = await useApi.get('/admin/users', {
      params: {
        limit: state.perPage,
        page: state.page.current,
        states: state.filter.states.length ? [...state.filter.states].join(',') : undefined,
        roles: state.filter.roles.length ? [...state.filter.roles].join(',') : undefined,
        from_date: state.filter.createdAt && state.filter.createdAt[0] ? dayjs(state.filter.createdAt[0]).format('YYYYMMDD') : undefined,
        to_date: state.filter.createdAt && state.filter.createdAt[1] ? dayjs(state.filter.createdAt[1]).format('YYYYMMDD') : undefined,
        q: state.filter.search.length > 2 ? state.filter.search : undefined,
        lang: state.filter.lang || undefined,
      },
    });

    commit('USERS_SET', response.results);
    commit('USERS_TOTAL_SET', response.count);
  },

  async changePage({ commit, dispatch }, to) {
    commit('PAGE_UPDATE', { current: to });
    await dispatch('getUsers');
  },

  async addUser({ commit }, payload) {
    const user = await useApi.post('/admin/users', {
      body: payload,
    });

    commit('USER_ADD', user);
  },

  async updateUser({ commit }, { id, data }) {
    await useApi.patch(`/admin/users/${id}`, {
      body: data,
    });

    commit('USER_UPDATE', { id, data });
  },

  async addEmployee({ commit }, { userId, data }) {
    const employees = await useApi.post(`/admin/users/${userId}/employees`, {
      body: data,
    });

    commit('USER_EMPLOYEES_SET', employees);
  },

  async updateEmployee({ commit }, { id, data, userId }) {
    await useApi.put(userId ? `/client/user/${userId}/employees/${id}` : `/client/user/employees/${id}`, {
      body: data,
    });

    commit('USER_EMPLOYEE_UPDATE', { id, userId, data });
  },

  async getUser({ commit }, id) {
    const user = await useApi.get(`/admin/users/${id}`);

    commit('USER_SET', user);
  },

  async removeUser({ commit }, id) {
    await useApi.delete(`/admin/users/${id}`);

    commit('USER_REMOVE', id);
  },

  async getUsersForExport({ commit, state }) {
    const response = await useApi.get('/admin/users', {
      params: {
        limit: 999999,
        page: 1,
        states: state.filter.states.length ? [...state.filter.states].join(',') : undefined,
        roles: state.filter.roles.length ? [...state.filter.roles].join(',') : undefined,
        from_date: state.filter.createdAt && state.filter.createdAt[0] ? dayjs(state.filter.createdAt[0]).format('YYYYMMDD') : undefined,
        to_date: state.filter.createdAt && state.filter.createdAt[1] ? dayjs(state.filter.createdAt[1]).format('YYYYMMDD') : undefined,
        q: state.filter.search.length > 2 ? state.filter.search : undefined,
        lang: state.filter.lang || undefined,
      },
    });

    return response;
  },
};
